import React, { useState, useEffect } from 'react';
import ServiceHandler from '../services/service-handler';
export var IS_LTO = 'LTO';
export var LtoContext = React.createContext({
    isLTO: null,
});
export var LTO_MRTGCODE = '6S2T9F2T415MQ';
export var LtoContextProvider = function (_a) {
    var children = _a.children;
    var _b = useState(null), isLTO = _b[0], setIsLTO = _b[1];
    var urlParams = new URLSearchParams(window.location.search);
    var promoCode = urlParams.get('mktgCode');
    useEffect(function () {
        if (promoCode == LTO_MRTGCODE || sessionStorage.getItem("LTO")) {
            sessionStorage.setItem(IS_LTO, 'true');
            setIsLTO(true);
        }
    }, [ServiceHandler.AuthenticationService.getCatBundle()]);
    return React.createElement(LtoContext.Provider, { value: [isLTO, setIsLTO] }, children);
};
