var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useRef } from 'react';
import { useContent } from '../../../hooks/use-content';
import { CtaButton, CtaSecondaryButton } from '../cta/cta';
import ServiceHandler from '../../../services/service-handler';
import styles from './are-you-sure.module.scss';
import './are-you-sure.scss';
import { useAmplitude } from '../../../hooks/use-amplitude';
function AreYouSure(_a) {
    var contentFragment = _a.contentFragment, href = _a.href, onClose = _a.onClose, onCancel = _a.onCancel, onContinue = _a.onContinue, _b = _a.hasSubText, hasSubText = _b === void 0 ? true : _b, titleClass = _a.titleClass, externalToFordRedirect = _a.externalToFordRedirect;
    var _c = useContent(contentFragment)[0], content = _c.content, getValueByTitle = _c.getValueByTitle, getCtaProps = _c.getCtaProps;
    var logAmplitudeEvent = useAmplitude().logAmplitudeEvent;
    var cfg = ServiceHandler.ConfigService;
    var theOverlay = useRef();
    useEffect(function () {
        if (content) {
            logAmplitudeEvent("modal dialog", {
                title: getValueByTitle('title'),
            });
            theOverlay.current.style.opacity = '.8';
        }
    }, [content]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: styles.overlay, ref: theOverlay }),
        content && (React.createElement("div", { className: styles.overlayPopupContainer },
            React.createElement("div", { className: styles.overlayPopup },
                React.createElement("div", { style: { display: 'flex', flexDirection: 'column' } },
                    React.createElement("div", { className: styles.closeIcon, onClick: function () { return onClose(); }, style: {
                            color: 'black',
                            cursor: 'pointer',
                            display: 'flex',
                            alignSelf: 'flex-end',
                            flexDirection: 'column',
                            backgroundSize: 'cover',
                            backgroundImage: "url(".concat(cfg.AEM_BASE_URL, "/content/dam/loyalty/").concat(ServiceHandler.ConfigService.BRAND_NAME, "/us/en-us/images/closeIcon.svg)"),
                        } }),
                    React.createElement("div", null,
                        React.createElement("div", { className: "".concat(styles.overlayTitle, " ").concat(titleClass) }, getValueByTitle('title')),
                        hasSubText && React.createElement("div", { className: styles.overlayCopy }, getValueByTitle('copy')))),
                React.createElement("div", null,
                    React.createElement(CtaButton, __assign({}, getCtaProps('okay button'), { href: externalToFordRedirect || !onContinue ? href : null, onClick: onContinue, target: '_blank' })),
                    React.createElement(CtaSecondaryButton, __assign({}, getCtaProps('Cancel Button'), { onClick: onCancel }))))))));
}
export default AreYouSure;
