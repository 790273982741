//////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////
export var SOCT_URL = 'https://www.firstbankcard.com/lynx/#/soct/LV1Q1LD62KMVW'; // eslint-disable-line no-redeclare
//////////
export var LPS_API_PATH = {
    TRANSACTIONS: '/api/rewards-account-info/v2/customer/transactions',
    POINTS_BALANCES: '/api/rewards/v2/customer/points',
    MESSAGES: '/api/messagecenter/v3/messages',
    USERS: '/api/users',
    VEHICLES: '/api/users/v2/userWithVehicles',
    DEALER: '/api/dealersearch/v3/dealer',
    GET_REWARDS: '/api/reward-catalog/v1/customer/rewards',
    REDEEM_REWARD: '/api/reward-catalog/v2/customer/rewards/{price-plan-code}',
    ORDER_HISTORY: '/api/rewards-account-info/v1/customer/order-history',
    DEALER_LOOKUP: '/api/member-assist/v1/dealers',
    POINTS_ASSIST_SERVICE: '/api/member-assist/v1/repair/points',
    POINTS_ASSIST_SALE: '/api/member-assist/v1/sale/points',
    CREDIT_CARD_PREFILL: '/api/member-assist/v1/customer/credit-card/application-prefill',
    CREDIT_CARD_ELIGIBILITY: '/api/member-assist/v1/customer/credit-card/availability',
    ACTIVATE: '/api/rewards/customer-activity/v1/customer/activities/{code}',
    TAX_CALCULATION: '/api/reward-catalog/v1/tax-calculation',
    CLAIM_POINTS: '/api/rewards/customer-activity/v1/customer/activities',
    FORDPAY_TOKEN: '/api/reward-catalog/v1/fordpay-token',
    ADDRESS_LOOKUP: '/api/reward-catalog/v1/address-standardization',
    FMA_SWAP_TOKEN: '/api/token/v2/swap-token',
    MEMBER2MEMBER: '/api/rewards/customer-activity/v1/friend-referrals',
};
export var FPS_API_PATH = {
    EXTERNALREF: '/fps/api/externalref/set',
    PERSONALIZATION: '/fps/api/personalization_1_8/get',
};
export var DEFAULT_LANGUAGE_CODE = 'en';
export var DEFAULT_COUNTRY_CODE = 'us';
export var DEFAULT_REGION_CODE = 'us';
export var LANGUAGE_CODE_EN_CA = "en-".concat(DEFAULT_REGION_CODE);
export var LANGUAGE_CODE_FR_CA = "fr-".concat(DEFAULT_REGION_CODE);
export var SELECTED_VEHICLE_VIN_KEY = 'CX_SELECTED_VEHICLE_VIN';
export var POST_SUCCESS_MESSAGE = 'success';
export var POST_SUCCESS_ERROR_CODE = 'SUCCESS';
export var POST_ERROR_MESSAGE = 'error';
export var COUPON_TYPE = 'LARCouponType';
export var SD_AVAILABLE = 'isSDAvailable';
export var REWARD_TYPE = 'LARRewardType';
export var SHIPPING_COST = 'shippingCost';
export var REWARD_REDEEM_LOW_ERROR = '[TRN_COUPONS_LEVEL_TOO_LOW]';
export var fordVideoEN = {
    playerId: 'rkZxOrQ1M',
    accountId: '2270581146001',
    videoId: '111111',
};
export var larVideoEN = {
    playerId: 'S1UjnO7kM',
    accountId: '2267017475001',
    videoId: '111111',
};
